import axios from 'axios';

import { DASHBOARD } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarDashboard = (tipo) => async dispatch => {
    try {
        if(tipo){
            await dispatch(changeLoader(true));
            const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/dashboard/${tipo}`);
            await dispatch(changeLoader(false));

            await dispatch({
                type: DASHBOARD.OBTENER,
                payload: res.data.datos
            });
        }else{
            await dispatch({
                type: DASHBOARD.OBTENER,
                payload: null
            });
        }
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const generarReporteInversion = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/inversion`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        await dispatch({
            type: DASHBOARD.TEASER,
            payload: fileURL
        });

        //Open the URL on new Window
        //window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const descargarReporteInversion = () => async dispatch => {
    await dispatch({
        type: DASHBOARD.TEASER,
        payload: null
    });
}