import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../actions/breadcrumb';

import FiltrosEmpresas from './FiltrosEmpresas';

const Reportes = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Reportes',
            path: '/reportes'
        }
    ];

    const dispatch = useDispatch();

    const [empresaReporte, setEmpresaReporte] = useState(false);

    /* Buscar */
    const handleFiltrosEmpresa = (valor) => {
        setEmpresaReporte(valor);
    }

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return(
        <Fragment>

            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .titulo {
                            color: #111F62;
                        }

                        .subtitulo {
                            margin: 0px;
                        }
                        
                        .buttonIcon {
                            padding: 20px;
                            font-size: 30px;
                            background-color: #fab615;
                            color: #000000;
                            border-radius: 10px;
                            margin-bottom: 15px;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <FiltrosEmpresas empresaReporte={empresaReporte} reestablecerFiltros={() => handleFiltrosEmpresa(false)} />
                
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-8">
                            <h3>Reportes</h3>
                        </div>
                    </div>
                    <div className="row mb-3 mt-5">
                        <div className="offset-md-3 col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style" onClick={() => handleFiltrosEmpresa(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="certificaciones_academico">
                                        <i className="buttonIcon fa-solid fa-building-columns" />
                                        <h5 className="titulo">Empresas</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de las empresas registradas dentro del sistema.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Reportes;