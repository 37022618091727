import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { formatNumber } from '../../utils/generalFunctions';

import { buscarDashboard, generarReporteInversion, descargarReporteInversion } from '../../actions/dashboard';

const DashboardEmpresa = () => {

    const dispatch =  useDispatch();

    const { detalles, url_teaser } = useSelector(state => state.dashboard);
    const { usuario } = useSelector(state => state.auth);

    const [empresaData, setEmpresaData] = useState({
        informacion_general: {
            nombre: null,
            capital: null,
            valuacion: null,
            steam: false,
            uso_steam: null,
            descripcion: null,
            propuesta_valor: null,
            productos_servicios: null,
            modelo_ingresos: null,
            mercado_meta: null,
            problema: null,
            uso_fondeo: null, 
            status: 0, 
            url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        },
        colaboradores: [],
        proyeccion_financiera: {
            proyeccion_ventas_1: null, 
            proyeccion_margen_1: null, 
            proyeccion_ebitda_1: null, 
            proyeccion_ebitda_porcentaje_1: null, 
            proyeccion_ventas_2: null, 
            proyeccion_crecimiento_2: null, 
            proyeccion_margen_2: null, 
            proyeccion_ebitda_2: null, 
            proyeccion_ebitda_porcentaje_2: null, 
            proyeccion_ventas_3: null, 
            proyeccion_crecimiento_3: null, 
            proyeccion_margen_3: null, 
            proyeccion_ebitda_3: null, 
            proyeccion_ebitda_porcentaje_3: null, 
            proyeccion_ventas_4: null, 
            proyeccion_crecimiento_4: null, 
            proyeccion_margen_4: null, 
            proyeccion_ebitda_4: null, 
            proyeccion_ebitda_porcentaje_4: null, 
            proyeccion_ventas_5: null, 
            proyeccion_crecimiento_5: null, 
            proyeccion_margen_5: null, 
            proyeccion_ebitda_5: null, 
            proyeccion_ebitda_porcentaje_5: null
        }, 
        unit_economics: {
            margen_contribucion: null,
            adquisicion_clientes: null,
            ticket_promedio: null,
            frecuencia_compra: null,
            cliente_anual: null,
            vida_cliente: null
        }
    });

    const { informacion_general, colaboradores, proyeccion_financiera, unit_economics } = empresaData;

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const colaboradoresColumnas = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
            grow: 1.5
        },
        {
            name: 'Puesto',
            sortable: true, 
            selector: row => row.puesto
        }
    ];

    /* Funciones generales */
    const handleProcesar = async () => {
        await dispatch(generarReporteInversion({
            id: empresaData.informacion_general._id
        }));
    }

    const handleDescargar = async () => {
        await dispatch(descargarReporteInversion());
    }

    useEffect(() => {
        dispatch(buscarDashboard('empresa'));

        return () => {
            dispatch(descargarReporteInversion());
        }
    }, []);

    useEffect(() => {
        if(detalles){
            setEmpresaData({
                ...empresaData,
                informacion_general: detalles.informacion_general, 
                colaboradores: detalles.colaboradores, 
                proyeccion_financiera: detalles.proyeccion_financiera, 
                unit_economics: detalles.unit_economics
            });
        }
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #000000;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }

                        .textareCampo {
                            height: 100px !important;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h4 className="mb-5 mt-3">
                        Bienvenido {[usuario.nombre, usuario.apellido_paterno, usuario.apellido_materno].join(' ')}
                    </h4>
                    
                    <div className="row mb-5">
                        <div className="d-flex justify-content-end">
                            <Link to="/configuracion/perfil_empresa">
                                <button className="btn btn-primary">Editar empresa</button>
                            </Link>

                            {
                                url_teaser ? 
                                    <a className="ms-3" href={url_teaser} target="_blank">
                                        <button className="btn btn-success" onClick={() => handleDescargar()}>Descargar teaser</button>
                                    </a>
                                :
                                <button type="button" className="btn btn-warning ms-3" onClick={() => handleProcesar()}>Generar teaser</button>
                            }                        
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ informacion_general?.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png' }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                <h3 className="text-muted">
                                    <Badge bg={informacion_general?.status == 0 ? 'warning' : (informacion_general?.status == 1 ? 'danger' : 'success')}>
                                        {informacion_general?.status == 0  ? 'Pendiente' : (informacion_general?.status == 1 ? 'Rechazada' : 'Aprobada')}
                                    </Badge>
                                </h3>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información general
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-6">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{informacion_general?.nombre || 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">¿Apliqué steam en el proyecto?</label>
                                                <p>{informacion_general?.steam ? 'Si' : 'No'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">Necesidad de capital</label>
                                                <p>{informacion_general?.capital ? formatNumber(informacion_general?.capital, 2, '$ ', '') : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">Valuación estimada</label>
                                                <p>{informacion_general?.valuacion ? formatNumber(informacion_general?.valuacion, 2, '$ ', '') : 'Sin definir'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Descripción</label>
                                                <p>{informacion_general?.descripcion || 'Sin definir'}</p>
                                            </div>

                                            {
                                                informacion_general?.steam ? 
                                                    <div className='col-md-12'>
                                                        <label className='fw-bold'>¿Como apliqué steam en el proyecto?</label>
                                                        <p>{informacion_general?.uso_steam || 'Sin definir'}</p>
                                                    </div>
                                                :                                            
                                                    null
                                            }

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Propuesta de valor</label>
                                                <p>{informacion_general?.propuesta_valor || 'Sin definir'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Productos y servicios a ofrecer</label>
                                                <p>{informacion_general?.productos_servicios || 'Sin definir'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Modelo de ingresos (monetización)</label>
                                                <p>{informacion_general?.modelo_ingresos || 'Sin definir'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Mercado meta y competencia</label>
                                                <p>{informacion_general?.mercado_meta || 'Sin definir'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Problema a resolver</label>
                                                <p>{informacion_general?.problema || 'Sin definir'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Detalles y uso del fondeo de capital</label>
                                                <p>{informacion_general?.uso_fondeo || 'Sin definir'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Colaboradores
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={colaboradoresColumnas}
                                                data={colaboradores}
                                                noDataComponent="No hay colaboradores registrados"
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                            Proyección financiera
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <div className="d-flex align-items-start">
                                                <div className="nav flex-column nav-pills me-3" id="dias-tab" role="tablist" aria-orientation="vertical">
                                                    <button className="nav-link active" id="ano-1-tab" data-bs-toggle="pill" data-bs-target="#ano-1" type="button" role="tab" aria-controls="ano-1" aria-selected="true">Año 1</button>
                                                    <button className="nav-link" id="ano-2-tab" data-bs-toggle="pill" data-bs-target="#ano-2" type="button" role="tab" aria-controls="ano-2" aria-selected="false">Año 2</button>
                                                    <button className="nav-link" id="ano-3-tab" data-bs-toggle="pill" data-bs-target="#ano-3" type="button" role="tab" aria-controls="ano-3" aria-selected="false">Año 3</button>
                                                    <button className="nav-link" id="ano-4-tab" data-bs-toggle="pill" data-bs-target="#ano-4" type="button" role="tab" aria-controls="ano-4" aria-selected="false">Año 4</button>
                                                    <button className="nav-link" id="ano-5-tab" data-bs-toggle="pill" data-bs-target="#ano-5" type="button" role="tab" aria-controls="ano-5" aria-selected="false">Año 5</button>
                                                </div>
                                                
                                                <div className="tab-content w-100">
                                                    <div className="tab-pane fade row show active" id="ano-1" role="tabpanel" aria-labelledby="ano-1-tab">
                                                        <div className="col-md-12 mb-3 row">
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Ventas</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ventas_1 ? formatNumber(proyeccion_financiera?.proyeccion_ventas_1, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Margen de contribución</label>
                                                                <p>{proyeccion_financiera?.proyeccion_margen_1 ? formatNumber(proyeccion_financiera?.proyeccion_margen_1, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_1 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_1, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">% EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_porcentaje_1 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_porcentaje_1, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane fade row" id="ano-2" role="tabpanel" aria-labelledby="ano-2-tab">
                                                        <div className="col-md-12 mb-3 row">
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Ventas</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ventas_2 ? formatNumber(proyeccion_financiera?.proyeccion_ventas_2, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>
                                                            
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Crecimiento anual</label>
                                                                <p>{proyeccion_financiera?.proyeccion_crecimiento_2 ? formatNumber(proyeccion_financiera?.proyeccion_crecimiento_2, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Margen de contribución</label>
                                                                <p>{proyeccion_financiera?.proyeccion_margen_2 ? formatNumber(proyeccion_financiera?.proyeccion_margen_2, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_2 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_2, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">% EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_porcentaje_2 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_porcentaje_2, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane fade row" id="ano-3" role="tabpanel" aria-labelledby="ano-3-tab">
                                                        <div className="col-md-12 mb-3 row">
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Ventas</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ventas_3 ? formatNumber(proyeccion_financiera?.proyeccion_ventas_3, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>
                                                            
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Crecimiento anual</label>
                                                                <p>{proyeccion_financiera?.proyeccion_crecimiento_3 ? formatNumber(proyeccion_financiera?.proyeccion_crecimiento_3, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Margen de contribución</label>
                                                                <p>{proyeccion_financiera?.proyeccion_margen_3 ? formatNumber(proyeccion_financiera?.proyeccion_margen_3, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_3 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_3, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">% EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_porcentaje_3 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_porcentaje_3, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane fade row" id="ano-4" role="tabpanel" aria-labelledby="ano-4-tab">
                                                        <div className="col-md-12 mb-3 row">
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Ventas</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ventas_4 ? formatNumber(proyeccion_financiera?.proyeccion_ventas_4, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>
                                                            
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Crecimiento anual</label>
                                                                <p>{proyeccion_financiera?.proyeccion_crecimiento_4 ? formatNumber(proyeccion_financiera?.proyeccion_crecimiento_4, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Margen de contribución</label>
                                                                <p>{proyeccion_financiera?.proyeccion_margen_4 ? formatNumber(proyeccion_financiera?.proyeccion_margen_4, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_4 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_4, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">% EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_porcentaje_4 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_porcentaje_4, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane fade row" id="ano-5" role="tabpanel" aria-labelledby="ano-5-tab">
                                                        <div className="col-md-12 mb-3 row">
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Ventas</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ventas_5 ? formatNumber(proyeccion_financiera?.proyeccion_ventas_5, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>
                                                            
                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Crecimiento anual</label>
                                                                <p>{proyeccion_financiera?.proyeccion_crecimiento_5 ? formatNumber(proyeccion_financiera?.proyeccion_crecimiento_5, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">Margen de contribución</label>
                                                                <p>{proyeccion_financiera?.proyeccion_margen_5 ? formatNumber(proyeccion_financiera?.proyeccion_margen_5, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_5 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_5, 2, '$ ', '') : 'Sin definir'}</p>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="fw-bold">% EBITDA</label>
                                                                <p>{proyeccion_financiera?.proyeccion_ebitda_porcentaje_5 ? formatNumber(proyeccion_financiera?.proyeccion_ebitda_porcentaje_5, 2, '', ' %') : 'Sin definir'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            Unit Economics
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-6">
                                                <label className="fw-bold">Márgen de contribución ponderado</label>
                                                <p>{unit_economics?.margen_contribucion ? formatNumber(unit_economics?.margen_contribucion, 2, '', ' %') : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">Costo de adquisición de clientes</label>
                                                <p>{unit_economics?.adquisicion_clientes ? formatNumber(unit_economics?.adquisicion_clientes, 2, '$ ', '') : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">Valor de ticket promedio</label>
                                                <p>{unit_economics?.ticket_promedio ? formatNumber(unit_economics?.ticket_promedio, 2, '$ ', '') : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">Frecuencia de compra</label>
                                                <p>{unit_economics?.frecuencia_compra || 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">Valor de cliente anual</label>
                                                <p>{unit_economics?.cliente_anual ? formatNumber(unit_economics?.cliente_anual, 2, '$ ', '') : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="fw-bold">Valor total de vida de cliente</label>
                                                <p>{unit_economics?.vida_cliente ? formatNumber(unit_economics?.vida_cliente, 2, '$ ', '') : 'Sin definir'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardEmpresa;