import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber } from '../../utils/generalFunctions';

import { buscarDashboard } from '../../actions/dashboard';

const DashboardAdministrador = () => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.dashboard);
    const { usuario } = useSelector(state => state.auth);

    const [dashboardData, setDashboardData] = useState({
        administradores: null, 
        empresas: null, 
        empresas_pendientes: null, 
        empresas_rechazadas: null, 
        empresas_aceptadas: null
    });

    useEffect(() => {
        console.log('dui');
        dispatch(buscarDashboard('administrador'));
    }, []);

    useEffect(() => {
        if(detalles){
            setDashboardData({
                ...dashboardData, 
                administradores: detalles.administradores, 
                empresas: detalles.empresas, 
                empresas_pendientes: detalles.empresas_pendientes, 
                empresas_rechazadas: detalles.empresas_rechazadas, 
                empresas_aceptadas: detalles.empresas_aceptadas
            });
        }        
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .imagen_perfil {
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                            border: 2px solid #000000;
                            margin-right: 15px;
                        }

                        .titulo {
                            color: #111f62;
                        }

                        .indicador {
                            font-size: 18px;
                            margin: 0;
                        }

                        .subtitulo {
                            margin: 0;
                            font-weight: 700;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
        
            <div className="col-md-12 d-flex align-items-center mb-5">
                <h4 className="titulo">
                    Bienvenido {[usuario.nombre, usuario.apellido_paterno, usuario.apellido_materno].join(' ')}
                </h4>
            </div>

            <div className="offset-md-4 col-md-4 my-5">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Usuarios</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.administradores != null && dashboardData.administradores != undefined ? formatNumber(dashboardData.administradores, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">administradores</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.empresas != null && dashboardData.empresas != undefined ? formatNumber(dashboardData.empresas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">empresas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offset-md-4 col-md-4 mt-5">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Empresas</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.empresas_pendientes != null && dashboardData.empresas_pendientes != undefined ? formatNumber(dashboardData.empresas_pendientes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">pendientes</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.empresas_rechazadas != null && dashboardData.empresas_rechazadas != undefined ? formatNumber(dashboardData.empresas_rechazadas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">rechazadas</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.empresas_aceptadas != null && dashboardData.empresas_aceptadas != undefined ? formatNumber(dashboardData.empresas_aceptadas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">aceptadas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardAdministrador;