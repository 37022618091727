import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import empresas from './empresas';
import usuarios from './usuarios';
import reportes from './reportes';

export default combineReducers({
    alert, loader, breadcrumb, auth, dashboard, empresas, usuarios, reportes
});