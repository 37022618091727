import axios from 'axios';

import setAuthToken from '../utils/setAuthToken';

import { setAlert } from './alert';

import { LOGIN, LOGIN_ERROR, OBTENER_USUARIO, OBTENER_USUARIO_ERROR, LOGOUT } from './types';

// LOGIN USER
export const login = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(usuario);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/auth/login`, body, config);

        dispatch({
            type: LOGIN,
            payload: res.data
        });
    } catch (error) {
        if(error?.response?.data?.msg) {
            dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }

        dispatch({
            type: LOGIN_ERROR
        });
    }
}

// REGISTER USER
export const register = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(usuario);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/auth/registrar`, body, config);

        dispatch({
            type: LOGIN,
            payload: res.data
        });
    } catch (error) {
        if(error?.response?.data?.msg) {
            dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }

        dispatch({
            type: LOGIN_ERROR
        });
    }
}

// LOAD USER
export const loadUser = () => async dispatch => {
    if(localStorage.token){
        setAuthToken(localStorage.token);
    }

    try{
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/auth/sesion`);

        dispatch({
            type: OBTENER_USUARIO,
            payload: {
                usuario: res.data.usuario, 
                empresa: res.data.empresa
            }
        });
    } catch (error){         
        dispatch({
            type: OBTENER_USUARIO_ERROR
        });
    }
}

// LOGOUT
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
}