import React, { useEffect, useState, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { guardarPerfil } from '../../actions/configuracion';

const Perfil = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Perfil',
            path: '/perfil'
        }
    ];

    const dispatch = useDispatch();

    const { usuario } = useSelector(state => state.auth);
    
    const [perfilData, setPerfilData] = useState({
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        email: '',
        password: '',
        imagen: null
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined,
    });

    let img = createRef();

    /* Guardar perfil */
    const onChange = (e) => {
        setPerfilData({
            ...perfilData, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            dispatch(guardarPerfil(perfilData));
        } catch (error) {
            return false;
        }
    }

    /* Funciones generales */
    const onViewPassword = () => {
        let perfilInputType = document.getElementById('password').type;
        if(perfilInputType === 'password')document.getElementById('password').type = 'text';
        else document.getElementById('password').type = 'password';
    }

    useEffect(() => {
        setPerfilData({
            ...perfilData,
            id: usuario?._id,
            nombre: usuario?.nombre,
            apellido_paterno: usuario?.apellido_paterno,
            apellido_materno: usuario?.apellido_materno,
            email: usuario?.email,
            imagen: usuario?.url_perfil ? usuario?.url_perfil : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        });
    }, [usuario]);    

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return(
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #000000;
                        }
                        
                        .img-wrap{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #000000;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-8 offset-md-2 pb-4">
                    <h3 className="mb-3 mt-5">Información personal</h3>

                    <div className="row mt-5">
                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={perfilData.nombre} onChange={e => onChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={perfilData.apellido_paterno} onChange={e => onChange(e)}/>
                                    <label htmlFor="apellido_paterno">Apellido paterno</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={perfilData.apellido_materno} onChange={e => onChange(e)}/>
                                    <label htmlFor="apellido_materno">Apellido materno</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Email de acceso" id="email" name="email" value={perfilData.email} onChange={e => onChange(e)}/>
                                    <label htmlFor="email">Correo</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="password" className="form-control" placeholder="Contraseña de acceso" id="password" name="password" autoComplete="off" value={perfilData.password} onChange={e => onChange(e)}/>
                                    <label htmlFor="password">Contraseña</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-primary viewPassword" onClick={onViewPassword}><i className="fa-solid fa-eye"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button type="button" className="btn btn-success float-end" onClick={handleSubmit}>Guardar perfil</button>   
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Perfil;