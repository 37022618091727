import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { sortTableDateCreacion } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarUsuarios, limpiarUsuarios, filtarUsuarios } from '../../actions/usuarios';

import FiltrosUsuarios from './FiltrosUsuarios';
import EliminarUsuarioAdministrador from './EliminarUsuario';

const UsuariosAdministradores = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Usuarios',
            path: '/usuarios'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.usuarios);

    const { usuario } = useSelector(state => state.auth);

    const [usuarios, setUsuarios] = useState([]);

    const [usuarioEliminar, setUsuarioEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Apellido paterno',
            sortable: true, 
            selector: row => row.apellido_paterno,
        },
        {
            name: 'Apellido materno',
            sortable: true, 
            selector: row => row.apellido_materno,
        },
        {
            name: 'Correo',
            sortable: true, 
            selector: row => row.email,
        },
        {
            name: 'Fecha de creación',
            sortable: true, 
            sortFunction: sortTableDateCreacion, 
            cell: item => new Date(item.creacion).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarUsuario(row)}><i className="fas fa-trash text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/usuarios/editar/${row._id}`}><i className="fas fa-edit text-warning"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarUsuarios(true));
    }

    /* Eliminar usuario */
    const handleEliminarUsuario = (usuario) => {
        setUsuarioEliminar(usuario);
    }

    useEffect(() => {
        return () => {
            dispatch(limpiarUsuarios());
        }
    }, []);

    useEffect(() => {
        if(usuario._id){
            dispatch(buscarUsuarios({
                id: usuario.empresa || null, 
                nombre: null, 
                apellido_paterno: null, 
                apellido_materno: null, 
                fecha_inicio: null, 
                fecha_fin: null
            }));
        }
    }, [usuario]);

    useEffect(() => {
        setUsuarios(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosUsuarios/>
            <EliminarUsuarioAdministrador usuario={usuarioEliminar} reestablecerUsuario={handleEliminarUsuario} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Usuarios</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>

                        <Link to="/usuarios/nuevo">
                            <button className="btn btn-primary">Agregar usuario</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={usuarios}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UsuariosAdministradores;