import axios from 'axios';

import { CONFIGURACION_PERFIL } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Perfil */
export const guardarPerfil = (perfil) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_perfil_personal`, perfil, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_PERFIL.EDITAR_PERSONAL,
            payload: res.data.perfil
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const guardarPerfilEmpresa = (perfil) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_perfil_empresarial`, perfil, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_PERFIL.EDITAR_EMPRESARIAL,
            payload: res.data.perfil
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}