import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { buscarDashboard } from '../../actions/dashboard';
import { editarBreadcrumb } from '../../actions/breadcrumb';

import DashboardAdministrador from './DashboardAdministrador';
import DashboardEmpresa from './DashboardEmpresa';

const Dashboard = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Inicio',
            path: '/dashboard'
        }
    ];

    const dispatch = useDispatch();

    const usuario = useSelector(state => state.auth.usuario);

    useEffect(() => {
        console.log(usuario);
        return () => {
            dispatch(buscarDashboard(null));
        }
    }, []);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1 row">
                {
                    usuario.empresa ?
                        <DashboardEmpresa />
                    :
                        <DashboardAdministrador />
                }
            </div>
        </div>
    )
}

export default Dashboard;