import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Alert from './components/layout/Alert';
import Layout from './components/layout/Layout';
import PrivateRoute from './components/routing/PrivateRoute';
import ValidateSession from './components/routing/ValidateSession';
import NoMatch from './components/routing/NoMatch';

// Login
import Login from './views/auth/Login';
import Registro from './views/auth/Registro';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';

// Empresas
import Empresas from './views/empresas/Empresas';
import EditarEmpresa from './views/empresas/EditarEmpresa';
import DetallesEmpresa from './views/empresas/DetallesEmpresa';
import UsuariosEmpresa from './views/empresas/UsuariosEmpresa';

// Usuarios
import Usuarios from './views/usuarios/Usuarios';
import NuevoUsuario from './views/usuarios/NuevoUsuario';
import EditarUsuario from './views/usuarios/EditarUsuario';

// Reportes
import Reportes from './views/reportes/Reportes';

// Configuración
import Perfil from './views/configuracion/Perfil';
import PerfilEmpresa from './views/configuracion/PerfilEmpresa';

// Redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Layout></Layout>
					<section className="container-fluid principal-section">
						<Alert></Alert>

						<Routes>
							{/* Login */}
							<Route exact path="/" element={<ValidateSession><Login /></ValidateSession>}/>
							<Route exact path="/login" element={<ValidateSession><Login /></ValidateSession>}/>
							<Route exact path="/registro" element={<ValidateSession><Registro /></ValidateSession>}/>

							{/* Dashboard */}
							<Route path="/dashboard" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>

							{/* Empresas */}
							<Route path="/empresas" element={<PrivateRoute routePermission='empresas.ver'><Empresas /></PrivateRoute>}/>
							<Route path="/empresas/editar/:id" element={<PrivateRoute routePermission='empresas.editar'><EditarEmpresa /></PrivateRoute>}/>
							<Route path="/empresas/detalles/:id" element={<PrivateRoute routePermission='empresas.detalles'><DetallesEmpresa /></PrivateRoute>}/>
							<Route path="/empresas/usuarios/:id" element={<PrivateRoute routePermission='empresas.usuarios.ver'><UsuariosEmpresa /></PrivateRoute>}/>

							{/* Usuarios */}
							<Route path="/usuarios" element={<PrivateRoute routePermission='usuarios.ver'><Usuarios /></PrivateRoute>}/>
							<Route path="/usuarios/nuevo" element={<PrivateRoute routePermission='usuarios.agregar'><NuevoUsuario /></PrivateRoute>}/>
							<Route path="/usuarios/editar/:id" element={<PrivateRoute routePermission='usuarios.editar'><EditarUsuario /></PrivateRoute>}/>

							{/* Reportes */}
							<Route path="/reportes" element={<PrivateRoute><Reportes /></PrivateRoute>}/>

							{/* Configuración */}
							<Route path="/configuracion/perfil" element={<PrivateRoute routePermission='configuracion.perfil.ver'><Perfil /></PrivateRoute>}/>
							<Route path="/configuracion/perfil_empresa" element={<PrivateRoute routePermission='configuracion.perfil_empresa.ver'><PerfilEmpresa /></PrivateRoute>}/>
							
							{/* Cuando no matchea con nada*/}
							<Route path="*" element={<NoMatch />}/>
						</Routes>
					</section>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;