import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { login } from '../../actions/auth';

const Login = () => {

    const dispatch = useDispatch();

    const { isAuthenticated } = useSelector(state => state.auth);

    const [loginData, setLoginData] = useState({
        email: null,
        password: null
    });

    /* Login */
    const onChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    const handleKeyPressLogin = (e) => {
        if(e.keyCode === 13){
            submitLogin();
        }
    }

    const submitLogin = () => {
        dispatch(login(loginData));
    }

    /* Funciones generales */
    const onViewPassword = (origen) => {
        let loginInputType = document.getElementById(origen).type;
        
        if(loginInputType == 'password')document.getElementById(origen).type = 'text';
        else document.getElementById(origen).type = 'password';
    }

    useEffect(() => {
        if(isAuthenticated){
            window.location.href = '/dashboard';
        }
    }, [isAuthenticated]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        body{
                            background-color: #575757;
                        }
                        
                        .centradoVertical {
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        
                        .btnIniciar {
                            background: #0d6efd;
                            border-color: #0d6efd;
                        }
                        
                        .btnRecuperar {
                            color: #464AC0;
                        }
                        
                        .bgImg {
                            height: 100%;
                            opacity: 0.2;
                            position: absolute;
                        }
                        
                        .logoHeader {
                            height: 8vw;
                            min-height: 50px;
                            max-height: 80px;
                        }

                        .codigo {
                            margin: 0.8rem;
                            text-align: center;
                            border-radius: 25%;
                            width: 2.8rem;
                            height: 3rem;
                            background-color: #dddddd;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
                            border-color: #989898;
                            font-weight: bold;
                        }
                        
                        .codigo:focus {
                            background-color: white;
                            border-color: #b7b7b7;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-6 offset-md-3 centradoVertical">
                    <div className="card bg-black"> 
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 text-center mb-3">
                                    <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_hidrotalent-color.jpg'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                    <h5 className="card-title text-warning">Iniciar sesión</h5>
                                </div>

                                <div className="col-md-12">
                                    <div className="input-group mb-3">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" placeholder="Correo" id="email_login" name="email" value={loginData.email || ''} onChange={e => onChange(e)} onKeyDown={handleKeyPressLogin}></input>
                                            <label htmlFor="email_login">Correo</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12">
                                    <div className="input-group mb-3">
                                        <div className="form-floating">
                                            <input type="password" id="password_login" className="form-control" placeholder="Contraseña" name="password" value={loginData.password || ''} onChange={e => onChange(e)} onKeyDown={handleKeyPressLogin}></input>
                                            <label htmlFor="password_login">Contraseña</label>
                                        </div>
                                        <div className="input-group-append">
                                            <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('password_login')}><i className="fa-solid fa-eye"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group text-left">
                                    <a className="btn btn-link btnRecuperar" href="/registro">¿No tienes cuenta? Regístrate</a>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group mt-3 text-center">
                                        <button type="button" className="btn btn-primary w-100" onClick={() => submitLogin()}>Iniciar sesión</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Login;