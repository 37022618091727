// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Breadcrumb
export const EDITAR_BREADCRUMB = 'EDITAR_BREADCRUMB';

// Loader
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

// Auth
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const OBTENER_USUARIO_ERROR = 'OBTENER_USUARIO_ERROR';
export const LOGOUT = 'LOGOUT';

// Dashboard
export const DASHBOARD = {
    OBTENER: 'OBTENER_DASHBOARD', 
    TEASER: 'TEASER_DASHBOARD'
};

// Empresas
export const EMPRESAS = {
    BUSCAR: 'BUSCAR_EMPRESAS', 
    DETALLES: 'DETALLES_EMPRESA', 
    EDITAR: 'EDITAR_EMPRESA', 
    ELIMINAR: 'ELIMINAR_EMPRESA', 
    FILTROS: 'FILTRAR_EMPRESAS', 
    USUARIOS: {
        BUSCAR: 'BUSCAR_USUARIOS_EMPRESAS', 
        AGREGAR: 'AGREGAR_USUARIO_EMPRESA', 
        DETALLES: 'DETALLES_USUARIO_EMPRESA', 
        EDITAR: 'EDITAR_USUARIO_EMPRESA', 
        ELIMINAR: 'ELIMINAR_USUARIO_EMPRESA'
    }
};

// Usuarios
export const USUARIO = {
    BUSCAR: 'BUSCAR_USUARIOS', 
    AGREGAR: 'AGREGAR_USUARIO', 
    DETALLES: 'DETALLES_USUARIO', 
    EDITAR: 'EDITAR_USUARIO', 
    ELIMINAR: 'ELIMINAR_USUARIO', 
    FILTROS: 'FILTRAR_USUARIOS'
};

// Reportes
export const REPORTES = {
    BUSCAR_REQUERIMIENTOS: 'BUSCAR_REQUERIMIENTOS_REPORTES'
};

// Configuración
export const CONFIGURACION_PERFIL = {
    EDITAR_PERSONAL: 'EDITAR_PERFIL_PERSONAL', 
    EDITAR_EMPRESARIAL: 'EDITAR_PERFIL_EMPRESARIAL'
};