import React, { useEffect, createRef, Fragment } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { detallesEmpresa, editarEmpresa, limpiarEmpresa } from '../../actions/empresas';

const PerfilEmpresa = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Inicio',
            path: '/dashboard'
        },
        {
            activo: true,
            nombre: 'Editar empresa',
            path: '/configuracion/perfil_empresa'
        }
    ];

    const dispatch = useDispatch();
    
    const navigate = useNavigate();

    const { usuario } = useSelector(state => state.auth);
    const { detalles } = useSelector(state => state.empresas);

    const [empresaData, setEmpresaData] = useState({
        id: null,

        nombre: null,
        capital: null,
        valuacion: null,
        steam: false,
        uso_steam: null,
        descripcion: null,
        propuesta_valor: null,
        productos_servicios: null,
        modelo_ingresos: null,
        mercado_meta: null,
        problema: null,
        uso_fondeo: null, 

        colaboradores: [], 

        proyeccion_ventas_1: null, 
        proyeccion_margen_1: null, 
        proyeccion_ebitda_1: null, 
        proyeccion_ebitda_porcentaje_1: null, 
        proyeccion_ventas_2: null, 
        proyeccion_crecimiento_2: null, 
        proyeccion_margen_2: null, 
        proyeccion_ebitda_2: null, 
        proyeccion_ebitda_porcentaje_2: null, 
        proyeccion_ventas_3: null, 
        proyeccion_crecimiento_3: null, 
        proyeccion_margen_3: null, 
        proyeccion_ebitda_3: null, 
        proyeccion_ebitda_porcentaje_3: null, 
        proyeccion_ventas_4: null, 
        proyeccion_crecimiento_4: null, 
        proyeccion_margen_4: null, 
        proyeccion_ebitda_4: null, 
        proyeccion_ebitda_porcentaje_4: null, 
        proyeccion_ventas_5: null, 
        proyeccion_crecimiento_5: null, 
        proyeccion_margen_5: null, 
        proyeccion_ebitda_5: null, 
        proyeccion_ebitda_porcentaje_5: null, 

        margen_contribucion: null,
        adquisicion_clientes: null,
        ticket_promedio: null,
        frecuencia_compra: null,
        cliente_anual: null,
        vida_cliente: null, 
        
        imagen: null
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined
    });

    let img = createRef();

    /* Editar empresa */
    const handleChangeImg = () => {
        setProfilePicture({
            ...profilePicture, 
            img: img.current.files[0]
        });
    }

    const handleInputChange = ({ target }) => {
        setEmpresaData({
            ...empresaData,
            [target.name]: target.value
        });
    }

    const handleCheckbox = ({ target }) => {
        setEmpresaData({
            ...empresaData,
            steam: target.checked, 
            uso_steam: target.checked ? empresaData.uso_steam : null
        });
    }

    const handleEditarEmpresa = async () => {
        if(empresaData.nombre){
            let formData = new FormData();

            formData.append('id', empresaData.id);
            formData.append('nombre', empresaData.nombre);
            formData.append('capital', empresaData.capital);
            formData.append('valuacion', empresaData.valuacion);
            formData.append('steam', empresaData.steam);
            formData.append('uso_steam', empresaData.uso_steam);
            formData.append('descripcion', empresaData.descripcion);
            formData.append('propuesta_valor', empresaData.propuesta_valor);
            formData.append('productos_servicios', empresaData.productos_servicios);
            formData.append('modelo_ingresos', empresaData.modelo_ingresos);
            formData.append('mercado_meta', empresaData.mercado_meta);
            formData.append('problema', empresaData.problema);
            formData.append('uso_fondeo', empresaData.uso_fondeo);
            formData.append('proyeccion_ventas_1', empresaData.proyeccion_ventas_1);
            formData.append('proyeccion_margen_1', empresaData.proyeccion_margen_1);
            formData.append('proyeccion_ebitda_1', empresaData.proyeccion_ebitda_1);
            formData.append('proyeccion_ebitda_porcentaje_1', empresaData.proyeccion_ebitda_porcentaje_1);
            formData.append('proyeccion_ventas_2', empresaData.proyeccion_ventas_2);
            formData.append('proyeccion_crecimiento_2', empresaData.proyeccion_crecimiento_2);
            formData.append('proyeccion_margen_2', empresaData.proyeccion_margen_2);
            formData.append('proyeccion_ebitda_2', empresaData.proyeccion_ebitda_2);
            formData.append('proyeccion_ebitda_porcentaje_2', empresaData.proyeccion_ebitda_porcentaje_2);
            formData.append('proyeccion_ventas_3', empresaData.proyeccion_ventas_3);
            formData.append('proyeccion_crecimiento_3', empresaData.proyeccion_crecimiento_3);
            formData.append('proyeccion_margen_3', empresaData.proyeccion_margen_3);
            formData.append('proyeccion_ebitda_3', empresaData.proyeccion_ebitda_3);
            formData.append('proyeccion_ebitda_porcentaje_3', empresaData.proyeccion_ebitda_porcentaje_3);
            formData.append('proyeccion_ventas_4', empresaData.proyeccion_ventas_4);
            formData.append('proyeccion_crecimiento_4', empresaData.proyeccion_crecimiento_4);
            formData.append('proyeccion_margen_4', empresaData.proyeccion_margen_4);
            formData.append('proyeccion_ebitda_4', empresaData.proyeccion_ebitda_4);
            formData.append('proyeccion_ebitda_porcentaje_4', empresaData.proyeccion_ebitda_porcentaje_4);
            formData.append('proyeccion_ventas_5', empresaData.proyeccion_ventas_5);
            formData.append('proyeccion_crecimiento_5', empresaData.proyeccion_crecimiento_5);
            formData.append('proyeccion_margen_5', empresaData.proyeccion_margen_5);
            formData.append('proyeccion_ebitda_5', empresaData.proyeccion_ebitda_5);
            formData.append('proyeccion_ebitda_porcentaje_5', empresaData.proyeccion_ebitda_porcentaje_5);
            formData.append('margen_contribucion', empresaData.margen_contribucion);
            formData.append('adquisicion_clientes', empresaData.adquisicion_clientes);
            formData.append('ticket_promedio', empresaData.ticket_promedio);
            formData.append('frecuencia_compra', empresaData.frecuencia_compra);
            formData.append('cliente_anual', empresaData.cliente_anual);
            formData.append('vida_cliente', empresaData.vida_cliente);
            formData.append('colaboradores', JSON.stringify(empresaData.colaboradores));
            formData.append('perfil', profilePicture.img);

            await dispatch(editarEmpresa(formData));

            await navigate("/dashboard");
        }else{
            await dispatch(setAlert('Todos los campos son obligatorios', 'danger'));
        }
    }

    /* Colaboradores */
    const handleAgregarColaborador = () => {
        let colaboradores = empresaData.colaboradores;

        colaboradores.push({
            nombre: null, 
            puesto: null
        });

        setEmpresaData({ 
            ...empresaData, 
            colaboradores
        });
    }

    const handleColaboradorChange = ({ target }) => {
        let nombre_separado = target.name.split('Colaborador_');

        let campo = nombre_separado[0];
        let posicion = nombre_separado[1];
        
        let colaboradores = empresaData.colaboradores;

        colaboradores[posicion][campo] = target.value || '';

        setEmpresaData({
            ...empresaData,
            colaboradores
        });
    }

    const colaboradoresCampos = (colaboradores) => {

        let colaboradores_campos = [];

        if(colaboradores){
            for (var x = 0; x < colaboradores?.length; x++) {
                colaboradores_campos.push(
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id={'nombreColaborador_' + x} name={'nombreColaborador_' + x} value={colaboradores[x].nombre || ''} onChange={e => handleColaboradorChange(e)}/>
                                <label htmlFor={'nombreColaborador_' + x}>Nombre {x + 1}</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Puesto" id={'puestoColaborador_' + x} name={'puestoColaborador_' + x} value={colaboradores[x].puesto || ''} onChange={e => handleColaboradorChange(e)}/>
                                <label htmlFor={'puestoColaborador_' + x}>Puesto {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger deleteField" id={'eliminarContacto_' + x} onClick={e => handleEliminarContacto(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return colaboradores_campos;
    }

    const handleEliminarContacto = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let colaboradores = empresaData.colaboradores;

        colaboradores.splice(id_separado[1], 1);

        setEmpresaData({ 
            ...empresaData, 
            colaboradores
        });
    }

    useEffect(() => {
        dispatch(limpiarEmpresa());

        return () => {
            dispatch(limpiarEmpresa());
        }
    }, []);

    useEffect(() => {
        if(usuario.empresa){
            dispatch(detallesEmpresa(usuario.empresa));
            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [usuario.empresa]);

    useEffect(() => {
        if(detalles){
            setEmpresaData({
                ...empresaData,
                id: detalles.informacion_general._id || null,
                
                nombre: detalles.informacion_general.nombre || null,
                capital: detalles.informacion_general.capital || null,
                valuacion: detalles.informacion_general.valuacion || null,
                steam: detalles.informacion_general.steam || false,
                uso_steam: detalles.informacion_general.uso_steam || null,
                descripcion: detalles.informacion_general.descripcion || null,
                propuesta_valor: detalles.informacion_general.propuesta_valor || null,
                productos_servicios: detalles.informacion_general.productos_servicios || null,
                modelo_ingresos: detalles.informacion_general.modelo_ingresos || null,
                mercado_meta: detalles.informacion_general.mercado_meta || null,
                problema: detalles.informacion_general.problema || null,
                uso_fondeo: detalles.informacion_general.uso_fondeo || null, 

                colaboradores: detalles.colaboradores || [], 

                proyeccion_ventas_1: detalles.proyeccion_financiera.proyeccion_ventas_1 || null, 
                proyeccion_margen_1: detalles.proyeccion_financiera.proyeccion_margen_1 || null, 
                proyeccion_ebitda_1: detalles.proyeccion_financiera.proyeccion_ebitda_1 || null, 
                proyeccion_ebitda_porcentaje_1: detalles.proyeccion_financiera.proyeccion_ebitda_porcentaje_1 || null, 
                proyeccion_ventas_2: detalles.proyeccion_financiera.proyeccion_ventas_2 || null, 
                proyeccion_crecimiento_2: detalles.proyeccion_financiera.proyeccion_crecimiento_2 || null, 
                proyeccion_margen_2: detalles.proyeccion_financiera.proyeccion_margen_2 || null, 
                proyeccion_ebitda_2: detalles.proyeccion_financiera.proyeccion_ebitda_2 || null, 
                proyeccion_ebitda_porcentaje_2: detalles.proyeccion_financiera.proyeccion_ebitda_porcentaje_2 || null, 
                proyeccion_ventas_3: detalles.proyeccion_financiera.proyeccion_ventas_3 || null, 
                proyeccion_crecimiento_3: detalles.proyeccion_financiera.proyeccion_crecimiento_3 || null, 
                proyeccion_margen_3: detalles.proyeccion_financiera.proyeccion_margen_3 || null, 
                proyeccion_ebitda_3: detalles.proyeccion_financiera.proyeccion_ebitda_3 || null, 
                proyeccion_ebitda_porcentaje_3: detalles.proyeccion_financiera.proyeccion_ebitda_porcentaje_3 || null, 
                proyeccion_ventas_4: detalles.proyeccion_financiera.proyeccion_ventas_4 || null, 
                proyeccion_crecimiento_4: detalles.proyeccion_financiera.proyeccion_crecimiento_4 || null, 
                proyeccion_margen_4: detalles.proyeccion_financiera.proyeccion_margen_4 || null, 
                proyeccion_ebitda_4: detalles.proyeccion_financiera.proyeccion_ebitda_4 || null, 
                proyeccion_ebitda_porcentaje_4: detalles.proyeccion_financiera.proyeccion_ebitda_porcentaje_4 || null, 
                proyeccion_ventas_5: detalles.proyeccion_financiera.proyeccion_ventas_5 || null, 
                proyeccion_crecimiento_5: detalles.proyeccion_financiera.proyeccion_crecimiento_5 || null, 
                proyeccion_margen_5: detalles.proyeccion_financiera.proyeccion_margen_5 || null, 
                proyeccion_ebitda_5: detalles.proyeccion_financiera.proyeccion_ebitda_5 || null, 
                proyeccion_ebitda_porcentaje_5: detalles.proyeccion_financiera.proyeccion_ebitda_porcentaje_5 || null, 

                margen_contribucion: detalles.unit_economics.margen_contribucion || null,
                adquisicion_clientes: detalles.unit_economics.adquisicion_clientes || null,
                ticket_promedio: detalles.unit_economics.ticket_promedio || null,
                frecuencia_compra: detalles.unit_economics.frecuencia_compra || null,
                cliente_anual: detalles.unit_economics.cliente_anual || null,
                vida_cliente: detalles.unit_economics.vida_cliente || null, 

                imagen: detalles.informacion_general.url_perfil ? detalles.informacion_general.url_perfil : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
            });
        }
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #000000;
                        }
                        
                        .img-wrap{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #000000;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Editar empresa</h3>
                    
                    <div className="row mb-5">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={profilePicture.img ? URL.createObjectURL(profilePicture.img) : empresaData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={handleChangeImg}/> 
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="accordion" id="accordionDetalles">
                                
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Información general
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={empresaData.nombre} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="nombre">Nombre</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Necesidad de capital" id="capital" name="capital" value={empresaData.capital} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="capital">Necesidad de capital</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Valuación estimada" id="valuacion" name="valuacion" value={empresaData.valuacion} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="valuacion">Valuación estimada</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <div className="input-group-preppend">
                                                    <input type="checkbox" className="btn-check" id="steam" autoComplete="off" onChange={e => handleCheckbox(e)} checked={empresaData.steam ? true : false}/>
                                                    <label className="btn btn-outline-primary h-100" id="label_steam" htmlFor="steam">{empresaData.steam ? 'Apliqué Steam en mi proyecto' : 'No apliqué Steam en mi proyecto'}</label>
                                                </div>                                   
                                            </div>                              
                                        </div> 

                                        {
                                            empresaData.steam ?
                                                <div className="col-md-12 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <textarea className="form-control textareCampo" placeholder="¿Cómo aplicaste Steam en tu proyecto?" id="uso_steam" name="uso_steam" value={empresaData.uso_steam} onChange={e => handleInputChange(e)}></textarea>
                                                            <label htmlFor="descripcion">¿Cómo aplicaste Steam en tu proyecto?</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Descripción breve de la empresa" id="descripcion" name="descripcion" value={empresaData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="descripcion">Descripción breve de la empresa</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Propuesta de valor" id="propuesta_valor" name="propuesta_valor" value={empresaData.propuesta_valor} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="propuesta_valor">Propuesta de valor</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Productos y servicios a ofrecer" id="productos_servicios" name="productos_servicios" value={empresaData.productos_servicios} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="productos_servicios">Productos y servicios a ofrecer</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Modelo de ingresos (monetización)" id="modelo_ingresos" name="modelo_ingresos" value={empresaData.modelo_ingresos} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="modelo_ingresos">Modelo de ingresos (monetización)</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Mercado meta y competencia" id="mercado_meta" name="mercado_meta" value={empresaData.mercado_meta} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="mercado_meta">Mercado meta y competencia</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Problema a resolver" id="problema" name="problema" value={empresaData.problema} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="problema">Problema a resolver</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Detalles y uso del fondeo de capital" id="uso_fondeo" name="uso_fondeo" value={empresaData.uso_fondeo} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="uso_fondeo">Detalles y uso del fondeo de capital</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        Colaboradores
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-12 text-end mb-4">
                                            <button className="btn btn-primary float-end me-2" onClick={() => handleAgregarColaborador()}>Agregar colaborador</button>
                                        </div>

                                        { colaboradoresCampos(empresaData.colaboradores) }
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        Proyección financiera
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="d-flex align-items-start">
                                            <div className="nav flex-column nav-pills me-3" id="dias-tab" role="tablist" aria-orientation="vertical">
                                                <button className="nav-link active" id="ano-1-tab" data-bs-toggle="pill" data-bs-target="#ano-1" type="button" role="tab" aria-controls="ano-1" aria-selected="true">Año 1</button>
                                                <button className="nav-link" id="ano-2-tab" data-bs-toggle="pill" data-bs-target="#ano-2" type="button" role="tab" aria-controls="ano-2" aria-selected="false">Año 2</button>
                                                <button className="nav-link" id="ano-3-tab" data-bs-toggle="pill" data-bs-target="#ano-3" type="button" role="tab" aria-controls="ano-3" aria-selected="false">Año 3</button>
                                                <button className="nav-link" id="ano-4-tab" data-bs-toggle="pill" data-bs-target="#ano-4" type="button" role="tab" aria-controls="ano-4" aria-selected="false">Año 4</button>
                                                <button className="nav-link" id="ano-5-tab" data-bs-toggle="pill" data-bs-target="#ano-5" type="button" role="tab" aria-controls="ano-5" aria-selected="false">Año 5</button>
                                            </div>
                                            
                                            <div className="tab-content w-100">
                                                <div className="tab-pane fade row show active" id="ano-1" role="tabpanel" aria-labelledby="ano-1-tab">
                                                    <div className="col-md-12 mb-3 row">
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Ventas" id="proyeccion_ventas_1" name="proyeccion_ventas_1" value={empresaData.proyeccion_ventas_1} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ventas_1">Ventas</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Margen de contribución" id="proyeccion_margen_1" name="proyeccion_margen_1" value={empresaData.proyeccion_margen_1} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_margen_1">Margen de contribución</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="EBITDA" id="proyeccion_ebitda_1" name="proyeccion_ebitda_1" value={empresaData.proyeccion_ebitda_1} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_1">EBITDA</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="% EBITDA" id="proyeccion_ebitda_porcentaje_1" name="proyeccion_ebitda_porcentaje_1" value={empresaData.proyeccion_ebitda_porcentaje_1} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_porcentaje_1">% EBITDA</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade row" id="ano-2" role="tabpanel" aria-labelledby="ano-2-tab">
                                                    <div className="col-md-12 mb-3 row">
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Ventas" id="proyeccion_ventas_2" name="proyeccion_ventas_2" value={empresaData.proyeccion_ventas_2} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ventas_2">Ventas</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Crecimiento anual" id="proyeccion_crecimiento_2" name="proyeccion_crecimiento_2" value={empresaData.proyeccion_crecimiento_2} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_crecimiento_2">Crecimiento anual</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Margen de contribución" id="proyeccion_margen_2" name="proyeccion_margen_2" value={empresaData.proyeccion_margen_2} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_margen_2">Margen de contribución</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="EBITDA" id="proyeccion_ebitda_2" name="proyeccion_ebitda_2" value={empresaData.proyeccion_ebitda_2} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_2">EBITDA</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="% EBITDA" id="proyeccion_ebitda_porcentaje_2" name="proyeccion_ebitda_porcentaje_2" value={empresaData.proyeccion_ebitda_porcentaje_2} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_porcentaje_2">% EBITDA</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade row" id="ano-3" role="tabpanel" aria-labelledby="ano-3-tab">
                                                    <div className="col-md-12 mb-3 row">
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Ventas" id="proyeccion_ventas_3" name="proyeccion_ventas_3" value={empresaData.proyeccion_ventas_3} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ventas_3">Ventas</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Crecimiento anual" id="proyeccion_crecimiento_3" name="proyeccion_crecimiento_3" value={empresaData.proyeccion_crecimiento_3} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_crecimiento_3">Crecimiento anual</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Margen de contribución" id="proyeccion_margen_3" name="proyeccion_margen_3" value={empresaData.proyeccion_margen_3} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_margen_3">Margen de contribución</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="EBITDA" id="proyeccion_ebitda_3" name="proyeccion_ebitda_3" value={empresaData.proyeccion_ebitda_3} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_3">EBITDA</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="% EBITDA" id="proyeccion_ebitda_porcentaje_3" name="proyeccion_ebitda_porcentaje_3" value={empresaData.proyeccion_ebitda_porcentaje_3} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_porcentaje_3">% EBITDA</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade row" id="ano-4" role="tabpanel" aria-labelledby="ano-4-tab">
                                                    <div className="col-md-12 mb-3 row">
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Ventas" id="proyeccion_ventas_4" name="proyeccion_ventas_4" value={empresaData.proyeccion_ventas_4} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ventas_4">Ventas</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Crecimiento anual" id="proyeccion_crecimiento_4" name="proyeccion_crecimiento_4" value={empresaData.proyeccion_crecimiento_4} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_crecimiento_4">Crecimiento anual</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Margen de contribución" id="proyeccion_margen_4" name="proyeccion_margen_4" value={empresaData.proyeccion_margen_4} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_margen_4">Margen de contribución</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="EBITDA" id="proyeccion_ebitda_4" name="proyeccion_ebitda_4" value={empresaData.proyeccion_ebitda_4} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_4">EBITDA</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="% EBITDA" id="proyeccion_ebitda_porcentaje_4" name="proyeccion_ebitda_porcentaje_4" value={empresaData.proyeccion_ebitda_porcentaje_4} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_porcentaje_4">% EBITDA</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade row" id="ano-5" role="tabpanel" aria-labelledby="ano-5-tab">
                                                    <div className="col-md-12 mb-3 row">
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Ventas" id="proyeccion_ventas_5" name="proyeccion_ventas_5" value={empresaData.proyeccion_ventas_5} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ventas_5">Ventas</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Crecimiento anual" id="proyeccion_crecimiento_5" name="proyeccion_crecimiento_5" value={empresaData.proyeccion_crecimiento_5} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_crecimiento_5">Crecimiento anual</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="Margen de contribución" id="proyeccion_margen_5" name="proyeccion_margen_5" value={empresaData.proyeccion_margen_5} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_margen_5">Margen de contribución</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="input-group-text">$</div>
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="EBITDA" id="proyeccion_ebitda_5" name="proyeccion_ebitda_5" value={empresaData.proyeccion_ebitda_5} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_5">EBITDA</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group mb-3">
                                                            <div className="input-group">
                                                                <div className="form-floating">
                                                                    <input type="number" className="form-control" placeholder="% EBITDA" id="proyeccion_ebitda_porcentaje_5" name="proyeccion_ebitda_porcentaje_5" value={empresaData.proyeccion_ebitda_porcentaje_5} onChange={e => handleInputChange(e)}/>
                                                                    <label htmlFor="proyeccion_ebitda_porcentaje_5">% EBITDA</label>
                                                                </div>
                                                                <div className="input-group-text">%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                        Unit Economics
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Márgen de contribución ponderado" id="margen_contribucion" name="margen_contribucion" value={empresaData.margen_contribucion} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="margen_contribucion">Márgen de contribución ponderado</label>
                                                </div>
                                                <div className="input-group-text">%</div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Costo de adquisición de clientes" id="adquisicion_clientes" name="adquisicion_clientes" value={empresaData.adquisicion_clientes} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="adquisicion_clientes">Costo de adquisición de clientes</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Valor de ticket promedio" id="ticket_promedio" name="ticket_promedio" value={empresaData.ticket_promedio} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="ticket_promedio">Valor de ticket promedio</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Frecuencia de compra" id="frecuencia_compra" name="frecuencia_compra" value={empresaData.frecuencia_compra} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="frecuencia_compra">Frecuencia de compra</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Valor de cliente anual" id="cliente_anual" name="cliente_anual" value={empresaData.cliente_anual} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="cliente_anual">Valor de cliente anual</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Valor total de vida de cliente" id="vida_cliente" name="vida_cliente" value={empresaData.vida_cliente} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="vida_cliente">Valor total de vida de cliente</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleEditarEmpresa}>Guardar</button>
                            <Link to='/dashboard'><button className="btn btn-danger float-end me-2">Regresar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PerfilEmpresa;