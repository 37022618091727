import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { sortTableDateCreacion, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarEmpresas, filtarEmpresas } from '../../actions/empresas';

import FiltrosEmpresas from './FiltrosEmpresas';
import EliminarEmpresa from './EliminarEmpresa';

const Empresas = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Empresas',
            path: '/empresas'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.empresas);

    const [empresas, setEmpresas] = useState([]);

    const [empresaEliminar, setEmpresaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Cuentas de usuario',
            sortable: true, 
            selector: row => row.usuarios,
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning">Pendiente</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="success">Aceptada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: 'Fecha de creación',
            sortable: true, 
            sortFunction: sortTableDateCreacion, 
            cell: item => new Date(item.creacion).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarEmpresa(row)}><i className="fas fa-trash text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/empresas/editar/${row._id}`}><i className="fas fa-edit text-warning"></i></Link>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/empresas/detalles/${row._id}`}><i className="fas fa-circle-info text-info"></i></Link>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/empresas/usuarios/${row._id}`}><i className="fas fa-users text-info"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarEmpresas(true));
    }

    /* Eliminar empresa */
    const handleEliminarEmpresa = (empresa) => {
        setEmpresaEliminar(empresa);
    }

    useEffect(() => {
        dispatch(buscarEmpresas({
            nombre: null,
            categoria: null,
            status: null,
            fecha_inicio: null,
            fecha_fin: null
        }));
    }, []);

    useEffect(() => {
        setEmpresas(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosEmpresas/>
            <EliminarEmpresa empresa={empresaEliminar} reestablecerEmpresa={handleEliminarEmpresa} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Empresas</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={empresas}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Empresas;